<template>
	<v-container fluid>
		<v-col class="pa-1 pb-0">
			<v-row
				class="ma-0"
				align="center"
			>
				<v-col cols="12">
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="4"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="models.operator_yn"
								title="시공상태"
								:radio-group="admin_user_care_flo_statusItems"
							/>
						</v-col>
						<v-col
							cols="12"
							md="4"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="models.customer_yn"
								title="고객 정보 등록"
								:radio-group="admin_user_care_flo_statusItems"
							/>
						</v-col>
						<v-col
							cols="12"
							md="4"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="models.as_yn"
								title="A/S 신청여부"
								:radio-group="admin_user_care_flo_statusItems"
							/>
						</v-col>
						<v-col
							cols="12"
							md="4"
							class="pa-0"
						>
							<AdminItemsTableItem
								ref="agent_date"
								v-model="models.agent_date"
								title="시공일자"
								date-picker
							/>
						</v-col>
						<v-col
							cols="12"
							md="4"
							class="pa-0"
						>
							<AdminItemsTableItem
								ref="customer_date"
								v-model="models.customer_date"
								title="고객 정보 등록일자"
								date-picker
							/>
						</v-col>
						<v-col
							cols="12"
							md="4"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="models.keyword"
								title="대리점명/NFC코드명"
								input
								label="대리점명, NFC코드명으로 검색"
							/>
						</v-col>
					</v-row>
				</v-col>
				<v-row class="ma-0">
					<v-col
						align="start"
						class="pt-0 md-pl-4"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="primary"
							@click="makeExcelFile"
						>
							<v-icon class="md-pr-2">mdi-download</v-icon>
							<p class="hidden-md-and-down">excel download</p>
						</v-btn>
					</v-col>
					<v-col
						align="end"
						class="pt-0"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="olive"
							class="mr-2"
							@click="resetSearch"
						>
							<v-icon class="pr-2">refresh</v-icon>
							<p>초기화</p>
						</v-btn>
						<v-btn
							dark
							tile
							elevation="0"
							color="olive"
							@click="searchItems"
						>
							<v-icon class="pr-2">search</v-icon>
							<p>검색</p>
						</v-btn>
					</v-col>
				</v-row>
			</v-row>
		</v-col>
		<v-col class="pa-4 px-md-4 pt-0">
			<v-row class="ma-0">
				<v-col
					cols="12"
					md="4"
					class="pl-0"
				>
					<h2>대리점</h2>
					<AdminItemsTableTopbar
						:check-remove-model="checkRemoveModelInterior"
						:desserts-length="interiorDesserts.length"
						:hide="'노출순서'"
						@callCreate="onCreateItem('interior')"
						@callConfirmRemove="confirmRemoveInterior = true"
					/>
					<CommonDataTableGridType02
						v-model="modelInterior"
						:headers="interiorHeaders"
						:desserts="interiorDesserts"
						height="500px"
						:loading="loading"
						@open="onReadItemInterior"
						@edit="onEditItemInterior"
					/>
				</v-col>
				<v-col
					cols="12"
					md="8"
					class="pl-0"
				>
					<h2>NFC Code</h2>
					<AdminItemsTableTopbar
						:select-create="checkCreateModelNfc"
						:select-model="modelInterior"
						:check-remove-model="checkRemoveModelNfc"
						:desserts-length="nfcDesserts.length"
						:hide="'노출순서'"
						:nfc="true"
						@callCreate="onCreateItem('nfc')"
						@callConfirmRemove="confirmRemoveNfc = true"
					/>
					<CommonDataTableGridType03
						v-model="modelNfc"
						:headers="nfcHeaders"
						:desserts="filteredNfcData"
						height="500px"
						:loading="loading"
						@open="onReadItemNfc"
						@edit="onEditItemNfc"
					/>
				</v-col>
			</v-row>
		</v-col>
		<AdminUserCareFloCreateInterior
			v-if="createDialogInterior"
			v-model="createModelInterior"
			:title="common_title"
			:dialog="createDialogInterior"
			:mode="mode"
			:edit-item="modelInterior[0]"
			@close="closeDialog('interior')"
		/>

		<AdminUserCareFloReadInterior
			v-if="readModeInterior"
			:dialog="readModeInterior"
			:title="common_title"
			:read-item="readItem"
			@close="closeDialog('interior')"
		/>
		<AdminUserCareFloCreateNfc
			v-if="createDialogNfc"
			v-model="createModelNfc"
			:title="common_title"
			:dialog="createDialogNfc"
			:mode="mode"
			:agent-corno="modelInterior[0].agent_corno"
			:edit-item="modelNfc[0]"
			@close="closeDialog('nfc')"
		/>

		<AdminUserCareFloReadNfc
			v-if="readModeNfc"
			:dialog="readModeNfc"
			:title="common_title"
			:read-item="readItem"
			@close="closeDialog('nfc')"
		/>
		<CommonConfirm
			:dialog="confirmRemoveInterior || confirmRemoveNfc"
			:title="`${common_title} 삭제`"
			:text="`선택하신 목록이 모두 삭제 됩니다. <br/> 그래도 삭제하시겠습니까?`"
			@close="confirmRemoveInterior = confirmRemoveNfc = false"
			@submit="removeData"
		/>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	name: 'AdminUserCareFlo',
	mixins: [common, admin],
	data: () => ({
		mode: 'read',
		readModeInterior: false,
		readModeNfc: false,
		modelInterior: [],
		modelNfc: [],
		readItem: {},
		createModelInterior: null,
		createDialogInterior: false,
		confirmRemoveInterior: false,
		createModelNfc: null,
		createDialogNfc: false,
		confirmRemoveNfc: false,
		interiorHeaders: [
			{ text: '담당대리점', value: 'agent_name', width: '120px' },
			{ text: '대표자', value: 'agent_bossname', width: '90px' },
			{ text: '보기', value: 'data-read', width: '30px', sortable: false },
			{ text: '수정', value: 'data-edit', width: '30px', sortable: false },
		],
		nfcHeaders: [
			{ text: 'NFC Code', value: 'nfc_code', width: '110px' },
			{ text: '서비스 구분', value: 'care_type', width: '120px' },
			{ text: '바코드값', value: 'barcode', width: '100px' },
			{ text: '시공일자', value: 'operator_date', width: '100px' },
			// { text: '시공자 전화번호', value: 'operator_hand', width: '140px' },
			{ text: '고객명', value: 'cust_name', width: '100px' },
			// { text: '전화번호', value: 'cust_hand', width: '100px' },
			// { text: '주소', value: 'address', width: '150px' },
			{ text: '고객 정보 등록 일자', value: 'regi_date', width: '160px' },
			{ text: 'A/S 신청 횟수', value: 'as_count', width: '130px' },
			{ text: '보기', value: 'data-read', width: '60px', sortable: false },
			{ text: '수정', value: 'data-edit', width: '60px', sortable: false },
		],
		excelHeaders: [
			{ text: 'NFC Code', value: 'nfc_code' },
			{ text: '대리점명', value: 'agent_name' },
			{ text: '서비스 구분', value: 'care_type' },
			{ text: '바코드값', value: 'barcode' },
			{ text: '시공일자', value: 'operator_date' },
			{ text: '시공자 전화번호', value: 'operator_hand' },
			{ text: '고객명', value: 'cust_name' },
			{ text: '전화번호', value: 'cust_hand' },
			{ text: '주소', value: 'address' },
			{ text: '고객 정보 등록 일자', value: 'regi_date' },
			{ text: 'A/S 신청 횟수', value: 'as_count' },
		],
		models: {
			operator_yn: '',
			customer_yn: '',
			as_yn: '',
			agent_date: null,
			customer_date: null,
			keyword: null,
		},
		interiorDesserts: [], // 대리점 전체
		nfcDesserts: [], // NFC 전체
		previousRow: false,
		isFilteredNfc: false,
		loading: false,
	}),
	computed: {
		checkRemoveModelInterior() {
			console.log('this.modelInterior', this.modelInterior)
			return this.modelInterior !== null ? this.modelInterior.length > 0 : false
		},
		checkCreateModelNfc() {
			console.log('this.modelInterior', this.modelInterior)
			return this.modelInterior.length === 1
		},
		checkRemoveModelNfc() {
			console.log('this.modelNfc', this.modelNfc)
			return this.modelNfc !== null ? this.modelNfc.length > 0 : false
		},
		filteredNfcData() {
			let data = []
			this.modelInterior.forEach(x => {
				this.nfcDesserts.filter(y => y.agent_corno === x.agent_corno).forEach(item => data.push(item))
			})
			return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
		},
	},
	created() {
		this.getList()
	},
	mounted() {},
	destroyed() {},
	methods: {
		...mapActions(['ADMIN_ACT_USER_CARE_FLO']),
		async getList(params = {}) {
			this.loading = true
			const items = { method: 'search', params: params }
			await this.ADMIN_ACT_USER_CARE_FLO(items).then(res => {
				console.log('ADMIN_ACT_USER_CARE_FLO', res)
				this.interiorDesserts = this.modelInterior = res[0]
				this.nfcDesserts = res[1]
				this.loading = false
			})
		},
		searchItems() {
			const filterAgentDate = this.common_inputDateFilter(this.models.agent_date)
			const filterCustomerDate = this.common_inputDateFilter(this.models.customer_date)

			const params = {
				operator_yn: this.models.operator_yn || '',
				customer_yn: this.models.customer_yn || '',
				as_yn: this.models.as_yn || '',
				agent_date_s: filterAgentDate.startDate,
				agent_date_e: filterAgentDate.endDate,
				customer_date_s: filterCustomerDate.startDate,
				customer_date_e: filterCustomerDate.endDate,
				keyword: this.models.keyword || '',
			}
			console.log('search', params)

			this.getList(params)
		},
		resetSearch() {
			this.models.operator_yn = ''
			this.models.customer_yn = ''
			this.models.as_yn = ''
			this.models.agent_date = null
			this.models.customer_date = null
			this.models.keyword = null
			this.$refs.agent_date.$refs.datePicker.cancel()
			this.$refs.customer_date.$refs.datePicker.cancel()
			this.getList()
		},
		onCreateItem(type) {
			this.mode = 'create'
			type === 'interior' ? (this.createDialogInterior = true) : (this.createDialogNfc = true)
		},
		onReadItemInterior(item) {
			console.log('onReadItem', item)
			this.readItem = item
			this.readModeInterior = true
		},
		onReadItemNfc(item) {
			console.log('onReadItem', item)
			this.readItem = item
			this.readModeNfc = true
		},
		onEditItemInterior(item) {
			this.modelInterior = [item]
			this.mode = 'edit'
			this.createDialogInterior = true
		},
		onEditItemNfc(item) {
			this.modelNfc = [item]
			this.mode = 'edit'
			this.createDialogNfc = true
		},
		makeExcelFile() {
			let combinedData = []
			this.modelInterior.forEach(interior => {
				const matchingNfc = this.nfcDesserts.find(nfcItem => nfcItem.agent_corno === interior.agent_corno)
				const { agent_corno } = matchingNfc || {}
				const matchedNfcData = this.nfcDesserts.filter(nfc => nfc.agent_corno === agent_corno)
				console.log('matchedNfcData', matchedNfcData)

				if (matchedNfcData.length > 0) {
					matchedNfcData.forEach(nfc => {
						const combined = {
							nfc_code: nfc.nfc_code,
							agent_name: interior.agent_name,
							care_type: nfc.care_type,
							barcode: nfc.barcode,
							operator_date: nfc.operator_date,
							operator_hand: nfc.operator_hand,
							cust_name: nfc.cust_name,
							cust_hand: nfc.cust_hand,
							address: nfc.address,
							regi_date: nfc.regi_date,
							as_count: nfc.as_count,
						}
						combinedData.push(combined)
					})
				} else {
					const combined = {
						agent_name: interior.agent_name,
					}
					combinedData.push(combined)
				}
			})
			console.log('makeExcelFile', combinedData)

			this.common_create_excel(this.common_excel_data_convert(combinedData, this.excelHeaders), 'NFC 정보')
		},
		async removeData() {
			console.log('removeData', this.confirmRemoveInterior, this.confirmRemoveNfc)
			this.loading = true

			let params = {}
			if (this.confirmRemoveInterior) {
				params = this.modelInterior.map(item => {
					return { agent_corno: item.agent_corno }
				})
			} else if (this.confirmRemoveNfc) {
				params = this.modelNfc.map(item => {
					return { nfc_code: item.nfc_code }
				})
			}

			console.log(params)
			try {
				const items = { method: this.confirmRemoveInterior ? 'deleteInterior' : 'deleteNfc', params: params }
				await this.ADMIN_ACT_USER_CARE_FLO(items).then(() => {
					this.confirmRemoveInterior = false
					this.confirmRemoveNfc = false
					this.loading = false
					this.modelInterior = []
					this.modelNfc = []
					this.searchItems()
				})
			} catch (e) {
				console.log(e)
				this.confirmRemoveInterior = false
				this.confirmRemoveNfc = false
			}
		},
		closeDialog(type) {
			// this.modelInterior = []
			// this.modelNfc = []
			this.readModeInterior = false
			this.readModeNfc = false
			type === 'interior' ? (this.createDialogInterior = false) : (this.createDialogNfc = false)
			this.searchItems()
		},
	},
}
</script>
