<template>
	<v-container fluid>
		<v-col class="pa-1 pb-0">
			<v-row
				class="ma-0"
				align="center"
			>
				<v-col cols="12">
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="4"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="models.status"
								title="상태"
								:select="admin_as_all_statusItems"
							/>
						</v-col>
						<v-col
							cols="12"
							md="8"
							class="pa-0"
						>
							<AdminItemsTableItem
								ref="asDate"
								v-model="asDate"
								title="A/S 신청일"
								date-picker
							/>
						</v-col>
						<v-col
							cols="12"
							md="4"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="models.careType"
								title="서비스 구분"
								:select="admin_as_care_statusItems"
							/>
						</v-col>
						<v-col
							cols="12"
							md="8"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="models.keyword"
								title="제목/내용"
								input
							/>
						</v-col>
					</v-row>
				</v-col>
				<v-row class="ma-0">
					<v-col
						align="start"
						class="pt-0 md-pl-4"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="primary"
							@click="makeExcelFile"
						>
							<v-icon class="md-pr-2">mdi-download</v-icon>
							<p class="hidden-md-and-down">excel download</p>
						</v-btn>
					</v-col>
					<v-col
						align="end"
						class="pt-0"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="olive"
							class="mr-2"
							@click="resetSearch"
						>
							<v-icon class="pr-2">refresh</v-icon>
							<p>초기화</p>
						</v-btn>
						<v-btn
							dark
							tile
							elevation="0"
							color="olive"
							@click="searchItems"
						>
							<v-icon class="pr-2">search</v-icon>
							<p>검색</p>
						</v-btn>
					</v-col>
				</v-row>
			</v-row>
		</v-col>
		<v-col class="pa-4 px-md-4 pt-0">
			<CommonDataTableGridType02
				v-model="model"
				:headers="headers"
				:desserts="desserts"
				:height="common_tableHeight(500)"
				:loading="loading"
				readonly
				@open="onReadItem"
				@status="onEditStatusItem"
			/>
		</v-col>

		<AdminCustomAsCareFloRead
			v-if="readMode"
			:dialog="readMode"
			:title="common_title"
			:read-item="readItem"
			@close="closeDialog"
			@save="onEditStatusItem"
		/>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	name: 'AdminCustomAsCareFlo',
	mixins: [common, admin],
	data: () => ({
		mode: 'read',
		readMode: false,
		model: [],
		readItem: {},
		confirmRemove: false,
		headers: [
			{ text: '상태', value: 'data_status_as', width: '80px', sortable: false },
			{ text: '바코드', value: 'barcode', width: '80px' },
			{ text: '담당대리점', value: 'agent_name', width: '120px' },
			{ text: 'NFC Code', value: 'nfc_code', width: '80px' },
			{ text: '고객 이름', value: 'cust_name', width: '100px' },
			{ text: '서비스 구분', value: 'care_type', width: '100px' },
			{ text: '제목', value: 'title', width: '100px' },
			{ text: '첨부파일', value: 'link', width: '100px' },
			{ text: 'A/S접수일', value: 'receipt_date', width: '120px' },
			{ text: '처리자', value: 'treat_userid', width: '100px' },
			{ text: '처리일', value: 'treat_date', width: '150px' },
			{ text: '보 기', value: 'data-read', width: '30px', sortable: false },
		],
		models: {
			status: null,
			careType: null,
			id: null,
			keyword: null,
		},
		asDate: null,
		desserts: [],
		loading: false,
	}),
	computed: {
		checkRemoveModel() {
			return this.model !== null ? this.model.length > 0 : false
		},
	},
	watch: {
		model(val) {
			console.log('model', val)
		},
	},
	created() {
		this.getList()
	},
	mounted() {},
	destroyed() {},
	methods: {
		...mapActions(['ADMIN_ACT_CUSTOM_AS_CARE_FLO']),
		async getList(params = {}) {
			this.loading = true
			const items = { method: 'search', params: params }
			await this.ADMIN_ACT_CUSTOM_AS_CARE_FLO(items).then(res => {
				console.log('ADMIN_ACT_CUSTOM_AS_CARE_FLO', res)
				this.desserts = res
				this.loading = false
			})
		},
		searchItems() {
			let startDate
			let endDate

			if (this.asDate !== null) {
				startDate = this.common_dateFilter(this.asDate[0])
				endDate = this.asDate[1] === undefined ? startDate : this.common_dateFilter(this.asDate[1])
			} else {
				startDate = null
				endDate = null
			}

			const params = {
				progress_state: this.models.status?.value || '',
				care_type: this.models.careType?.value || '',
				keyword: this.models.keyword || '',
				regi_date_s: startDate,
				regi_date_e: endDate,
			}
			console.log('search', params)

			this.getList(params)
		},
		resetSearch() {
			this.models.status = null
			this.asDate = null
			this.models.careType = null
			this.models.keyword = null
			this.$refs.asDate.$refs.datePicker.cancel()
			this.getList()
		},
		onReadItem(item) {
			console.log('onReadItem', item)
			this.readItem = item
			this.readMode = true
		},
		makeExcelFile() {
			this.common_create_excel(this.common_excel_data_convert(this.desserts, this.headers), 'AS 신청')
		},
		async onEditStatusItem(item) {
			console.log('status api put push', item)
			this.loading = true
			const params = {
				progress_state: item.data_status_as?.value,
				as_count: item.as_count,
				nfc_code: item.nfc_code,
			}
			const items = { method: 'edit', params: params }

			await this.ADMIN_ACT_CUSTOM_AS_CARE_FLO(items).then(() => {
				console.log('ADMIN_ACT_CUSTOM_AS_CARE_FLO')
				this.searchItems()
				this.loading = false
			})
		},
		closeDialog() {
			this.model = []
			this.addItem = []
			this.readMode = false
			this.searchItems()
		},
	},
}
</script>
